<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "BusinessRule.EditBusinessRule",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons @methodSave="onSubmit('btn-save')" :isNewRecord="false" />
    </div>
  </div>
  <form class="form-businessrule-edit pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning mt-2 mb-0">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <ul class="nav nav-tabs mb-2 mt-0" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-general-tab"
          @click="tabValidation"
          data-bs-toggle="pill"
          data-bs-target="#pills-general"
          type="button"
          role="tab"
          aria-selected="true"
        >
          <i class="bi bi-gear-fill"></i>
          {{
            $t(
              "BusinessRule.GeneralSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
        v-if="this.businessRuleData.businessRuleTriggerTypeId == 5"
      >
        <button
          v-if="!String.isNullOrWhiteSpace(this.businessRuleData.name)"
          @click="tabValidation"
          class="nav-link"
          id="pills-cron-generator-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-cron-generator"
          type="button"
          role="tab"
          aria-selected="false"
        >
          <i class="bi bi-box"></i>
          {{
            $t(
              "Components.CronGenerator.Title",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button type="button" class="nav-link" @click="tabValidation" v-else>
          <i class="bi bi-box"></i>
          {{
            $t(
              "Components.CronGenerator.Title",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
      <li class="nav-item" role="presentation" v-else>
        <button
          v-if="!String.isNullOrWhiteSpace(this.businessRuleData.name)"
          @click="tabValidation"
          class="nav-link"
          id="pills-criteria-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-criteria"
          type="button"
          role="tab"
          aria-selected="false"
        >
          <i class="bi bi-funnel-fill"></i>
          {{
            $t(
              "BusinessRule.Criterias",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button type="button" class="nav-link" @click="tabValidation" v-else>
          <i class="bi bi-funnel-fill"></i>
          {{
            $t(
              "BusinessRule.Criterias",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane fade active show" id="pills-general" role="tabpanel">
        <div class="row row-cols-1">
          <div class="col col-md-4">
            <label for="Name" class="form-label required"
              >{{
                $t(
                  "BusinessRule.Name",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="Name"
              v-model="businessRuleData.name"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
          <div class="col col-md-4">
            <label for="FormulaName" class="form-label required">{{
              $t(
                "ObjectRelations.FormulaName",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <input
              type="text"
              class="form-control text-uppercase"
              id="FormulaName"
              disabled
              :value="businessRuleData.formulaName"
              :spellcheck="this.$isTextSpellCheck"
            />
          </div>

          <div class="col col-md-4">
            <label for="businessProcess" class="form-label">{{
              $t(
                "BusinessRule.BusinessProcess",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              :selectedData="selectedBusinessProcess"
              :data="businessProcessData"
              requestUrl="Brs-BusinessRuleBusinessProcess"
              :isGatewayRequest="true"
              @onChange="onChangeForBusinessProcess"
            />
          </div>
        </div>
        <div class="row row-cols-1 mt-3">
          <div class="col col-md-12">
            <label for="BusinessRuleTrigger" class="form-label required">{{
              $t(
                "BusinessRule.BusinessRuleTriggerType",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithLocalData"
              :selectedData="selectedBusinessRuleTrigger"
              :allowEmpty="false"
              :data="businessRuleTriggerData"
              @onChange="onChangeForBusinessRuleTriggerType"
            />
          </div>
        </div>
        <div class="row row-cols-1 mt-3">
          <div class="col col-md-6">
            <div class="form-check">
              <label for="IsActive" class="form-label">{{
                $t(
                  "BusinessRuleActions.IsActive",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="IsActive"
                v-model="businessRuleData.isActive"
              />
            </div>
          </div>
          <div class="col col-md-6">
            <div class="form-check">
              <label for="IsNotShowResultToClient" class="form-label">{{
                $t(
                  "BusinessRule.IsNotShowResultToClient",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="IsNotShowResultToClient"
                v-model="businessRuleData.isNotShowResultToClient"
              />
            </div>
          </div>
        </div>
        <div class="row row-cols-1 mt-3">
          <div class="col col-md-12">
            <label for="Description" class="form-label">{{
              $t(
                "BusinessRule.Description",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <textarea
              name="Description"
              class="form-control"
              id="Description"
              rows="5"
              v-model="businessRuleData.description"
              :spellcheck="this.$isTextSpellCheck"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-cron-generator" role="tabpanel">
        <template v-if="cronGeneratorIsVisible">
          <CronGenerator
            ref="cronGenerator"
            :init="businessRuleData.cronExpression"
          />
        </template>
      </div>
      <div class="tab-pane fade" id="pills-criteria" role="tabpanel">
        <div class="row row-cols-1">
          <div class="col col-md-6">
            <label for="CriteriaExplanation" class="form-label"
              >{{
                $t(
                  "BusinessRule.CriteriaExplanation",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="CriteriaExplanation"
              v-model="businessRuleData.criteriaExplanation"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
            />
          </div>
          <div class="col col-md-6">
            <label for="FirstRunIntervalInSeconds" class="form-label required"
              >{{
                $t(
                  "BusinessRule.FirstRunIntervalInSeconds",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="FirstRunIntervalInSeconds"
              v-model="businessRuleData.firstRunIntervalInSeconds"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
        </div>
        <div class="row row-cols-1">
          <div class="col col-md-6">
            <label for="RepeatIntervalInSeconds" class="form-label required"
              >{{
                $t(
                  "BusinessRule.RepeatIntervalInSeconds",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="RepeatIntervalInSeconds"
              v-model="businessRuleData.repeatIntervalInSeconds"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
          <div class="col col-md-6">
            <label for="RepeatCount" class="form-label required"
              >{{
                $t(
                  "BusinessRule.RepeatCount",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="RepeatCount"
              v-model="businessRuleData.repeatCount"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="form-check">
            <label for="IsExecuteForever" class="form-label">{{
              $t(
                "BusinessRule.IsExecuteForever",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <input
              class="form-check-input"
              type="checkbox"
              id="IsExecuteForever"
              v-model="businessRuleData.isExecuteForever"
            />
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="Validation" class="form-label"
            >{{
              $t(
                "BusinessRule.Validation",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
            <a
              @click="openFormulaEditor"
              class="cursor-pointer text-decoration-none text-primary ms-1"
            >
              <span
                ><img
                  width="20"
                  height="20"
                  :src="
                    require('@/assets/images/icons/function-primary.png')
                  " /></span
              >&nbsp;
              {{
                $t(
                  "Components.FormulaEditorModal.OpenFormulaEditor",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</a
            ></label
          >
          <textarea
            name="Validation"
            class="form-control"
            id="Validation"
            :spellcheck="this.$isTextSpellCheck"
            v-model="businessRuleData.validation"
            rows="5"
          ></textarea>
        </div>
        <template v-if="queryBuilderIsVisible">
          <QueryBuilder
            :criteria="businessRuleData.criterias"
            :criteriaExpression="businessRuleData.criteriaExpression"
            ref="sxQuerybuilder"
            :isCriteria="true"
          />
        </template>
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      classes="mt-3"
      @methodSave="onSubmit('btn-save')"
      :isNewRecord="false"
    />
  </form>
  <FormulaEditorModal
    :formulaField="formulaField"
    parentRecordPrefix=""
    @updateFormula="updateFormula"
  />
</template>
<script>
import "@/assets/css/fa-all.css";
import $ from "jquery";

import FormulaEditorModal from "@/components/custom/formula-editor/FormulaEditorModal.vue";
import CronGenerator from "@/components/custom/cron-generator/NCronTabGenerator.vue";
export default {
  name: "CustomObjectEditBusinessRule",
  components: {
    CronGenerator,
    FormulaEditorModal,
  },
  data() {
    return {
      formulaField: {},
      businessRuleTriggerData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRule.RecordCreated",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRule.RecordUpdated",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRule.RecordCreatedOrUpdated",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "BusinessRule.RecordDeleted",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 5,
          value: this.$t(
            "BusinessRule.TimeTriggered",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 6,
          value: this.$t(
            "BusinessRule.UserTriggered",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      queryBuilderIsVisible: false,
      cronGeneratorIsVisible: false,
      selectedBusinessRuleTrigger: [],
      businessProcessData: [],
      selectedBusinessProcess: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      businessRuleData: {},
      columnTabActive: false,
      query: "",
    };
  },
  methods: {
    openFormulaEditor() {
      this.formulaField = {
        formula: this.businessRuleData.validation,
        customObjectPublicId: this.businessRuleData.customObjectPublicId,
      };
      $(".btn-open-formula-editor-modal").trigger("click");
    },
    updateFormula(validation) {
      this.businessRuleData.validation = validation;
    },
    onChangeForBusinessProcess: function (selected) {
      this.businessRuleData.businessProcessId = selected.key;
      this.businessRuleData.businessProcess = selected.value;
    },
    onChangeForBusinessRuleTriggerType: function (selected) {
      this.businessRuleData.businessRuleTriggerType = selected.value;
      this.businessRuleData.businessRuleTriggerTypeId = selected.key;
    },
    tabValidation($event) {
      var form = $(".form-businessrule-edit");
      form.addClass("was-validated");
      if (form[0].checkValidity()) {
        form.removeClass("was-validated");
        this.columnTabActive = $event.target.id.includes("column");
      }
    },
    onSubmit(buttonName) {
      var form = $(".form-businessrule-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.businessRuleData.criterias = this.$refs.sxQuerybuilder.criterias;
      this.businessRuleData.criteriaExpression =
        this.$refs.sxQuerybuilder.expression;
      if (this.businessRuleData.businessRuleTriggerTypeId == 5) {
        this.businessRuleData.cronExpression = this.$refs.cronGenerator.value;
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-BusinessRuleUpdate", { ...this.businessRuleData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/BusinessRules/" + this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
        });
    },
    getBusinessRuleData() {
      debugger;
      this.queryBuilderIsVisible = false;
      this.cronGeneratorIsVisible = false;
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Brs-BusinessRuleGet?id={0}",
            this.$route.params.businessRuleId
          )
        )
        .then((response) => {
          this.businessRuleData = response.data;
          debugger;
          console.log(response.data);
          if (!String.isNullOrWhiteSpace(response.data.businessProcessId)) {
            this.selectedBusinessProcess.push({
              key: response.data.businessProcessId,
              value: response.data.businessProcess,
            });
          }
          if (
            !String.isNullOrWhiteSpace(response.data.businessRuleTriggerTypeId)
          ) {
            this.selectedBusinessRuleTrigger.push({
              key: response.data.businessRuleTriggerTypeId,
              value: this.businessRuleTriggerData.find(
                (f) => f.key == response.data.businessRuleTriggerTypeId
              ).value,
            });
          }

          this.queryBuilderIsVisible = true;
          this.cronGeneratorIsVisible = true;

          this.businessRuleData.customObjectFormulaName =
            this.$store.getters._customObjectDto.formulaName;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getBusinessRuleData();
  },
};
</script>
